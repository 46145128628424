<template>
	<div>
		<div class="centbox">

			<div style="width: 400px; margin: 0 auto; text-align: left;">
				<div style="padding: 20px 0; text-align: center; font-size: 20px; ">
					{{title}}
				</div>
				<div>
					<el-checkbox v-model="temp.jump_me" @change="updateProcess">审批人是发起人时，审批自动通过</el-checkbox>
				</div>
				<div style="padding: 20px 0; ">
					<el-checkbox v-model="temp.jump_more" @change="updateProcess">审批人在多个审核节点时自动通过</el-checkbox>
				</div>


			</div>
			<div>
				<div class="nodeitem">
					<div class="nodetitle notedit">发起人</div>
					<div class="nodecontent">表单发起人</div>
				</div>
				<div class="arrowbox" >
					<div class="arrowline"></div>
					<div class="arrowbar"></div>
					<div class="btnadd" @click="addStep(0)">
						<el-button icon="el-icon-plus" size="mini" circle="" type="primary"></el-button>
					</div>
				</div>
			</div>
			<div v-for="(p,idx) in StepList" :key="idx">
				<div @click="showItem(p)" :class="['nodeitem',EditItem.id==p.id?'nodecdk':''] ">
					<div class="nodetitle ">
						第{{stepName[idx+1]}}步：

						<span v-if="p.sp_type=='one'">指定人审批</span>
						<span v-if="p.sp_type=='dept_leader'">发起人部门领导审批</span>
						<span v-if="p.sp_type=='fgxz'">分管校长审批</span>
						<span v-if="p.sp_type=='dept_role'">按部门角色审批</span>
						<span v-if="p.sp_type=='sys_role'">按系统角色审批</span>
						<span v-if="p.sp_type=='yuxuan'">从预选中选择审批人</span>
						<span v-if="p.sp_type=='notsure'">审批人自选下级审批人</span>

						<span class="steptype" v-if="p.step_type=='and'">会签</span>
						<span class="steptype" v-if="p.step_type=='or'">或签</span>
						<span class="steptype" v-if="p.step_type=='notsure'">自选</span>


						<div class="remove" title="删除该流程" @click.stop="deleteStep(p)">×</div>
					</div>
					<div class="nodecontent">
						<div v-if="p.sp_type=='one'||p.sp_type=='yuxuan'">
							<div class="spuser" v-for="(u,i) in p.target" :key="i">
								<img :src="u.avatar||temphead" alt=""> {{u.username}}
							</div>
						</div>
						<div v-if="p.sp_type=='dept_leader'">
							<div class="spuser">
								发起人的部门领导
							</div>
						</div>
						<div v-if="p.sp_type=='fgxz'">
							<div class="spuser">
								分管校长审批
							</div>
						</div>
						<div v-if="p.sp_type=='notsure'">
							<div class="spuser">
								审批人自选下级审批人
							</div>
						</div>
						<div v-if="p.sp_type=='dept_role'">
							<div class="spuser" v-for="(u,i) in p.target" :key="i">
								{{u.dept_name}}-{{u.role_name}}
							</div>
						</div>

						<div v-if="p.sp_type=='sys_role'">
							<div class="spuser" v-for="(u,i) in p.target" :key="i">
								系统角色-{{u.role_name}}
							</div>
							
						</div>


					</div>
				</div>
				<div class="arrowbox">
					<div class="arrowline"></div>
					<div class="arrowbar"></div>
					<div class="btnadd">
						<el-button icon="el-icon-plus" size="mini" circle="" type="primary" @click="addStep(idx+1)">
						</el-button>
					</div>
				</div>
			</div>

			<div>
				<div class="nodeitem">
					<div class="nodetitle notedit">流程结束-抄送人列表<div class="remove" title="清空抄送人" @click.stop="deleteCC()">
							×</div>
					</div>
					<div class="nodecontent">
						<div class="spuser" v-for="(u,i) in CCList" :key="i">
							<img :src="u.avatar||temphead" alt=""> {{u.username}}
						</div>

						<span v-if="CCList.length==0">无抄送</span>
						<el-button icon="el-icon-plus" size="mini" circle="" @click="ccdialog=true"></el-button>
					</div>
				</div>

			</div>
		</div>


		<el-dialog title="流程编辑" :visible.sync="isShowModal" width="900px" :close-on-click-modal="false">
			<div style="width: 80%; margin: 0 auto;">
				<el-form ref="form" :model="EditItem" label-width="80px">
					<el-row :gutter="20">
						<el-col :span="12">
							<el-form-item label="审批类型:">
								<el-select v-model="EditItem.step_type" placeholder="请选择审批类型">
									<el-option value="and" label="会签"></el-option>
									<el-option value="or" label="或签"></el-option>
									<el-option value="notsure" label="审批人自选"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="终止流程:">
								<el-select v-model="EditItem.can_end" placeholder="审批人是否可终止流程">
									<el-option :value="1" label="可终止"></el-option>
									<el-option :value="0" label="不可终止"></el-option>

								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="审批人:">
								<el-select v-model="EditItem.sp_type" placeholder="请选择审批人">
									<el-option value="one" label="指定审批人"></el-option>
									<el-option value="dept_leader" label="发起人部门领导"></el-option>
									<el-option value="fgxz" label="分管校长审批"></el-option>
									<el-option value="dept_role" label="选择部门角色"></el-option>
									<el-option value="sys_role" label="选择系统角色"></el-option>
									<el-option value="yuxuan" label="从预选中选择"></el-option>
									<el-option value="notsure" label="审批人自选下级审批人"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-button v-if="EditItem.sp_type=='one'||EditItem.sp_type=='dept_role'||EditItem.sp_type=='sys_role'||EditItem.sp_type=='yuxuan'" 
							icon="el-icon-plus" round="" @click="DeptUserDialog=true">选择对象</el-button>
						</el-col>
					</el-row>

					<el-row >
						<div style="border: 1px solid #CCCCCC; padding: 20px;">
							<div>已选择的审批对象：</div>
							<div style="padding-top: 20px;">
								<div v-if="EditItem.sp_type=='one'||EditItem.sp_type=='yuxuan'">
									<div class="spuser" v-for="(u,i) in EditItem.sp_users" :key="i">
										<img :src="u.avatar||temphead" alt=""> {{u.username}}
									</div>
								</div>

								<div v-if="EditItem.sp_type=='dept_role'||EditItem.sp_type=='sys_role'">
									<div class="spuser" v-for="(u,i) in EditItem.sp_users" :key="i" v-if="u.role_name">
										<span
											v-if="EditItem.sp_type=='dept_role'">部门角色：{{u.dept_name}}-{{u.role_name}}</span>
										<span v-if="EditItem.sp_type=='sys_role'">系统角色：{{u.role_name}}</span>
									</div>
								</div>
								
								<div v-if="EditItem.sp_type=='notsure'">
									<div class="spuser" >
										<span>审批人自选下级审批人</span>
											
										
									</div>
								</div>
								
								<div v-if="EditItem.sp_type=='fgxz'">
									<div class="spuser" >
										<span>分管校长审批</span>
											
										
									</div>
								</div>
								<div v-if="EditItem.sp_type=='dept_leader'">
									<div class="spuser" >
										<span>发起人部门领导审批</span>
											
										
									</div>
								</div>
								

							</div>
						</div>
					</el-row>
					<div style="text-align: right; padding-top: 20px;">
						<el-button type="primary" icon="el-icon-s-claim" @click="saveStep">保存流程</el-button>
					</div>


				</el-form>
			</div>
		</el-dialog>
		<deptuser module="zichan" :peizhi="1" :odata="EditItem.sp_users" :type="EditItem.sp_type" :mult="true" v-if="DeptUserDialog" v-on:getUser="getUser"></deptuser>

		<deptuser type="" :peizhi="1" v-if="ccdialog" :mult="true" v-on:getUser="getCCUser"></deptuser>
	</div>
</template>


<script>
	import deptuser from "./deptuser.vue"
	export default {
		props: ["code","title"],
		components: {
			deptuser
		},
		data() {
			return {
				temphead: "//school.mmteck.cn/static/temp.png",
				isShowModal: false,
				ccdialog: false,
				StepList: [],
				CCList: [],
				processId: 0,
				temp: {
					jump_more: false,
					jump_me: false
				},
				stepName: {
					1: "一",
					2: "二",
					3: "三",
					4: "四",
					5: "五",
					6: "六",
					7: "七",
					8: "八",
					9: "九"
				},
				EditItem: {
					id: 0,
					step_type: "and",
					process_id: 0,
					sp_type: "",
					step: "",
					sp_users: [],
					remark: "",
					can_end: 0,
				},
				DeptUserDialog: false
			}
		},

		mounted() {
			this.getProcess()
      console.log(1111111)
		},
		methods: {
			getProcess() {
				this.$http.post("/api/form_process_one", {
					process_code: this.code
				}).then(res => {
					this.processId = res.data.id;
					this.StepList = res.data.steps;
					res.data.jump_me ? res.data.jump_me = true : res.data.jump_me = false;
					res.data.jump_more ? res.data.jump_more = true : res.data.jump_more = false;
					this.temp = res.data;
					this.CCList = res.data.cc
				})
			},
			updateProcess() {
				this.$http.post("/api/form_process_temp_edit", this.temp).then(res => {

				})
			},
			showItem(p) {
				this.EditItem = JSON.parse(JSON.stringify(p))
				this.EditItem.sp_users = this.EditItem.target
				this.EditItem.sp_users.map(e=>{ e.id=e.target_id})
				this.isShowModal = true
				console.log(this.EditItem.sp_users )
			},
			addStep(idx) {
				this.EditItem = {
					id: 0,
					step_type: "and",
					process_id: this.processId,
					sp_type: "",
					step: (idx),
					sp_users: [],
					remark: "",
					can_end: 0,
				}

				this.isShowModal = true

			},
			getUser(e) {
				
				if (e) {
					this.EditItem.sp_users = e
				}
				this.DeptUserDialog = false

				console.log(this.EditItem)
			},
			getCCUser(e) {
				if (e) {
					
					this.$http.post("/api/form_process_cc_save", {
						process_id: this.processId,
						users: e
					}).then(res => {
						if(res.data.code==200){
							this.$message.success("保存成功！")
						}
						this.getProcess()
					})
				}
				this.ccdialog = false
			},
			saveStep() {
				
				this.$http.post("/api/form_process_step_save", this.EditItem).then(res => {
					this.isShowModal = false
					if(res.data.code==200){
						this.$message.success("保存成功！")
					}
					this.getProcess()
				})
			},
			deleteCC() {
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					this.$http.post("/api/form_process_cc_save", {
						process_id: this.processId,
						users: []
					}).then(res => {
						this.$message({
							type: 'success',
							message: "清空成功！"
						});
						this.getProcess()
					})



				}).catch(() => {

				});
			},
			deleteStep(item) {
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					this.$http.post("/api/form_process_step_delete", {
						id: item.id
					}).then(res => {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.getProcess()
					})


				}).catch(() => {

				});
			}

		}
	}
</script>
<style scoped="scoped">
	.centbox {
		width: 800px;
		border: 1px solid #ccc;
		margin: 0 auto;
		text-align: center;
		font-size: 14px;
		padding: 20px;
	}

	.nodeitem {
		width: 400px;
		border: 2px solid #fff;
		margin: 0 auto;
		box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
		border-radius: 4px;
		margin-top: 5px;
	}

	.nodecdk {
		border: 2px solid rgba(255, 69, 0, 0.8);
		box-shadow: 0 0 5px 2px rgba(255, 69, 0, 0.8);
	}

	.nodetitle {

		text-align: left;
		padding: 5px 10px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		background-color: rgb(255, 148, 62);
		color: #FFFFFF;
	}

	.notedit {
		background-color: #999999;
		color: #FFFFFF;
	}

	.nodecontent {
		padding: 10px;
		position: relative;
	}

	.arrowbox {
		position: relative;
		height: 80px;
	}

	.arrowline {
		height: inherit;
		width: 2px;
		background-color: rgb(255, 148, 62);
		margin: 0 auto;
		position: relative;
		z-index: 2;
	}

	.arrowbar {
		width: 0;
		height: 0;
		border-top: 6px solid #fff;
		border-right: 6px solid #fff;
		border-bottom: 6px solid rgb(255, 148, 62);
		border-left: 6px solid rgb(255, 148, 62);
		margin: 0 auto;
		position: relative;
		z-index: 1;
		transform: rotateZ(-45deg) translate3d(8px, -8px, 0);
		transform-origin: center;


	}

	.btnadd {
		position: absolute;
		left: 50%;
		bottom: 30px;
		transform: translateX(-50%);
		z-index: 3;
		transform-origin: center;
	}

	.remove {
		width: 20px;
		height: 20px;
		color: #fff;
		float: right;
		text-align: center;
		line-height: 20px;
		cursor: pointer;
		opacity: 0;
	}

	.nodetitle:hover>.remove {
		opacity: 1;
	}


	.spuser {
		display: inline-block;
		border: 1px solid rgb(255, 148, 62);


		padding: 2px;
		margin-right: 10px;
		margin-bottom: 5px;
		border-radius: 4px;
		padding: 4px 10px;
	}

	.spuser img {
		width: 20px;
		height: 20px;
		display: inline-block;
		vertical-align: top;
		border-radius: 50%;
	}

	.steptype {
		background-color: #058e71;
		font-size: 12px;
		padding: 2px 5px;
		margin-left: 10px;
		border-radius: 2px;

		display: inline-block;
	}
</style>
