<template>
	<div class="wraper cbox">
		<div class="headbar">

			<div style="padding-top: 14px;">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item>耗材申购审批流程</el-breadcrumb-item>
					<el-breadcrumb-item>流程设置</el-breadcrumb-item>

				</el-breadcrumb>
			</div>

		</div>
		<div style="height: 85%;overflow-y: scroll;">

			

			<processmaker code="hcsg" title="耗材申购审批流程" v-if="activeName=='耗材申购审批流程'"></processmaker>
		

			<div style="height: 50px;">

			</div>

		</div>
	</div>
</template>

<script>
	import processmaker from '../com/processmaker'
	import dayjs from 'dayjs'
	export default {
		components: {
			processmaker
		},

		data() {
			return {
				activeName: "耗材申购审批流程",
				


			};
		},
		mounted() {

		},
		methods: {
			changeTab() {
				console.log(this.activeName)
			}



		}
	}
</script>
